import React from "react";

import useTrialAuthClaimCheck, { TRIAL_PATH, PURCHASE_PATH } from "../../../components/profile/shared/AuthClaimCheckHook";
import PageContentContainer from "../../../components/profile/shared/PageContentContainer";
import StatusContent, { useTrialDataApi } from "../../../components/profile/trial/StatusContent";

import { pushSAEvent } from "../../../helpers";

export default ({ location }) => {
    const loading = useTrialAuthClaimCheck(TRIAL_PATH);
    const { getTenantStatus, tenantStatus } = useTrialDataApi();
    const [menuItems, setMenuItems] = React.useState([
        {
            path: TRIAL_PATH,
            text: 'Trial',
            open: true,
        }
    ]);

    React.useEffect(() => {
        if (!loading) {
            getTenantStatus();
        }
    }, [loading]);

    React.useEffect(() => {
        if (tenantStatus && tenantStatus.TrialDelivered && tenantStatus.TrialStatus !== 'Purchase Pending') {
            setMenuItems([
                {
                    path: TRIAL_PATH,
                    text: 'Trial',
                    open: true,
                },
                {
                    path: PURCHASE_PATH,
                    text: 'Purchase',
                },
            ]);
        }
    }, [tenantStatus]);

    pushSAEvent('visit_profile_trial_status');

    return (
        <PageContentContainer
            title="Your profile | Trial"
            location={location}
            content={<StatusContent
                tenantStatus={tenantStatus}
                getTenantStatus={getTenantStatus}
            />}
            menuItems={menuItems}
            loading={loading}
        />
    );
};